#portfolio-page {
  font-family: "Helvetica Neue";
}

.item {
  width: 100%;
}

.reveal,
img {
  align-self: center;
}

video {
  align-self: center;
}

@media (max-width: 1077px) {
  section .container {
    margin: 10px;
  }
  .portfolio-to-section {
    display: none;
  }

  .portfolio-legend {
    font-size: 12px;
    margin-top: 5px;
  }
  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
}

@media (min-width: 768px) {
  section .container {
    margin: 50px;
    display: flex;
    flex-direction: column;
  }
  .portfolio-legend {
    font-size: 16px;
    margin-top: 15px;
  }
  .portfolio-legend-title {
    margin-bottom: 10px;
  }
  section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
}

.portfolio-link {
  margin: 5px 27px;
  font-size: 14px;
  font-weight: bold;
}

.header-band.portfolio {
  background: white;
  position: fixed;
  z-index: 3;
}

//
.portfolio-scroller {
  // margin-top: 165px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  // min-height: 100px;
  // border: 3px solid red;
  // position: relative;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// section:nth-child(1) {
//   color: black;
// }

// section:nth-child(2) {
//   color: #1d212b;
// }

// section:nth-child(3) {
//   color: #000000;
// }

// section:nth-child(4) {
//   color: #000000;
// }

section h2 {
  font-size: 20px;
  text-align: left;
  text-transform: uppercase;
}

.reveal {
  position: relative;
  // opacity: 0;
  display: flex;
  flex-direction: column;
}

.reveal.one {
  opacity: 100%;
}

.container {
  display: flex;
  flex-direction: column;
}
.grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.filter-button:hover {
  background-color: #000;
  color: #fff;
}

@media (min-width: 1078px) {
  .portfolio-nav {
    position: sticky;
    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
    font-family: "Helvetica Neue";
    letter-spacing: 2.03px;
    align-self: center;
    margin-top: 30px;
    z-index: 1;
    width: 100%;
    background: white;
    padding: 0 8%;
    user-select: none;
    margin: 1.5%;
  }
  .filter-button {
    background: #fff;
    display: flex;
    height: 32px;
    justify-content: center;
    align-items: center;
    color: #000;
    font-family: "Helvetica Neue";
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;
    border: 1px solid #000;
    padding: 20px 15px 15px;

    margin-right: 10px;
    cursor: pointer;
  }
}

@media (max-width: 1077px) {
  .portfolio-nav {
    position: sticky;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-transform: uppercase;
    font-family: "Helvetica Neue";
    letter-spacing: 2.03px;
    align-self: center;

    z-index: 1;
    width: 100%;
    background: white;
    padding: 0 8%;
    user-select: none;
    margin: 1% 0;
  }
  .filter-button {
    text-transform: uppercase;
    background: #fff;
    display: flex;
    height: 27.5px;
    justify-content: center;
    align-items: center;
    font-family: "Helvetica Neue";
    font-size: 14px;
    display: flex;
    border: 1px solid #000;
    // border-radius: 20px;
    padding: 8px 13px 4px;
    margin-right: 5px;
    margin-bottom: 10;
    cursor: pointer;
  }
}

.active {
  background-color: #000;
  color: #fff;
}

#about-pages {
  background-color: black;
  color: white;
  font-family: "Helvetica Neue";
  height: 100vh;
  position: relative;
}

#main-about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-info {
  display: inline-flex;
  align-items: flex-end;
  gap: 153px;
}

.about-info-texts {
  display: flex;
  width: 630px;
  flex-direction: column;
  align-items: flex-start;
  gap: 349px;
}

.about-svg {
  height: 20%;
}

.about-text {
  width: 100%;
  font-size: 14px;
  letter-spacing: 1.42px;
  color: #fff;
  text-align: justify;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.about-img {
  height: 100%;
  width: 100%;
}

.about-images {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.about-images:hover {
  filter: blur(2);
  transition-duration: 1s;
}

// .about-img {
//   height: 600px;
// }

.header-band.about {
  margin-bottom: 81px;
}

@media (max-width: 1200px) {
  .about-text {
    width: 377px;
    align-self: stretch;
  }

  .about-info-texts {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 377px;
  }

  .about-info {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 377px;
    gap: 61px;
    margin-bottom: 5%;
  }
}

@media (min-width: 1200px) and (max-width: 999px) {
  // .about-img {
  //   height: 450px;
  //   margin-top: 50%;
  // }
  // .about-images {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: flex-start;
  //   align-items: flex-start;
  // }
  .about-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50%;
    height: 100%;
  }
  .about-title {
    margin-bottom: 115px;
  }
  .header-band.about {
    background-color: white;
    position: fixed;
  }
  .about-text {
    width: 347px;
  }
  .about-svg {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  // .about-title {
  //   margin: 39px 18px 0px;
  // }
  .header-band.about {
    margin-bottom: 10px;
  }
}

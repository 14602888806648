#contact-pages {
  background-color: black;
  color: white;
  font-family: "Helvetica Neue";
  height: 100vh;
  position: relative;
}

.contact {
  width: 50%;
  background-color: white;
  color: black;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.contact-logo-space {
  width: 50%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-logo-space img {
  animation: blurAnimation 5s infinite; // Animation en boucle
}

@keyframes blurAnimation {
  0% {
    filter: blur(0px); // Image nette
  }
  50% {
    filter: blur(4.7px); // Image floue
  }
  100% {
    filter: blur(0px); // Retour à nette
  }
}

.contact-social {
  position: absolute;
  bottom: 46px;
  right: 149px;
  display: flex;
  width: 417px;
  flex-direction: column;
  align-items: flex-end;
  gap: 70px;
  color: black;
  padding-left: 45px;
}

.contact-social h1 {
  text-align: right;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.contact-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 58px;
  align-self: stretch;
}

.contact-type {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
}

.contact-link-name {
  text-align: right;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contact-network {
  text-align: right;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.contact-network:hover {
  color: gray;
  transition-duration: 0.5s;
}

@media (max-width: 1200px) {
  #contact-pages {
    background-color: white;
  }
  #main-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact {
    display: none;
  }
  .contact-title {
    font-size: 30px;
    letter-spacing: 3.05px;
  }
  .contact-logo-space {
    display: none;
  }
  .contact-social {
    position: static;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 168px;
  }
  .contact-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 58px;
    align-self: stretch;
  }
  .contact-type {
    align-items: flex-start;
  }

  .contact-type {
    text-align: left;
  }

  .contact {
    width: 100%;
  }
}

.filter-buttons {
  display: flex;
}

.filter-btn {
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  border-radius: 5px;
}

.filter-btn.active {
  color: #fff;
}

.header-band {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
  z-index: 0;
  color: black;
}

.logo {
  width: 168px;
  margin-left: 8%;
}

.burger {
  width: 58px;
  margin-left: 85px;
  margin-right: 8%;
}

@media (min-width: 1077px) {
  .portfolio-nav {
    display: flex;
    // justify-content: flex-end;
    text-transform: uppercase;
    font-family: "helvetica Neue";
    font-size: 20px;
    letter-spacing: 2.03px;
    // align-self: flex-end;
  }
}

@media (max-width: 1077px) {
  .burger {
    display: block;
  }
  .portfolio-link {
    display: none;
  }
  .header-band-portfolio {
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgb(255, 255, 255);
    align-items: center;
    padding: 50px 0;
    z-index: 1;
    position: fixed;
  }
  .portfolio-scroller {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

#index-page {
  background-color: black;
  color: white;
  font-family: "Helvetica Neue";
  height: 100vh;
  position: relative;
}

.home-visuel {
  display: flex; /* Utilisation de Flexbox pour centrer */
  justify-content: center; /* Centrage horizontal */
  align-items: center; /* Centrage vertical */
  height: 100vh; /* Prend toute la hauteur du viewport */
  width: 100vw;
  max-width: 100%;
  display: flex;
  justify-content: center; // Centre horizontalement
  align-items: center; // Centre verticalement
  // height: 100vh;
}

.home-to-section {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 46px;
  left: 60px;
  color: #fff;
  font-family: "Helvetica Neue";
  font-size: 34px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 13.6px;
  text-transform: uppercase;
}

.home-link {
  color: white;
}

@media (max-width: 1077px) {
  .home-to-section {
    z-index: 2;
    // display: none;
    color: #fff;

    /* Nav_mobile */
    font-family: "Helvetica Neue";
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 9.6px;
    text-transform: uppercase;
    left: 44px;
    bottom: 40px;
  }
  .home-visuel img {
    width: 230px;
  }
  .home-visuel {
    z-index: 0;
  }
}

.home-link {
  margin: 15px 27px;
}

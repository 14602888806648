.footer-contain {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: 46px;
  right: 60px;
}

.footer-copyright {
  color: #fff;
  text-align: right;
  /* credits_desktop */
  font-family: "Helvetica Neue";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 2;
}

@media (max-width: 1200px) {
  .footer-copyright {
    font-size: 12px;
    display: none;
  }
}

@media (max-width: 1077px) {
  .footer-copyright {
    color: #fff;
    text-align: right;
    /* credits_desktop */
    font-family: "Helvetica Neue";
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 2;
  }

  .footer-contain {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
    margin-top: 5%;
    position: absolute;
    width: 372px;
  }
}

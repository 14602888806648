/* ====================================
// css variables
// ==================================== */

:root {
}

/* ====================================
// reset rules
// ==================================== */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  font-size: 1rem;
  line-height: 1.25;
  font-family: century-gothic;
  margin: 0px;
}

a {
  color: inherit;
  text-decoration: none;
}

img,
video {
  max-inline-size: 100%;
  block-size: auto;
  vertical-align: middle;
}

/* ====================================
// base rules
// ==================================== */

// .row + .row {
//   margin-top: 16px;
// }

// .row > .col,
// .row > [class^="col-"] {
//   padding-top: 12px;
//   padding-bottom: 12px;
//   background-color: rgba(86, 61, 124, 0.15);
//   border: 1px solid rgba(86, 61, 124, 0.2);
// }

h2 {
  font-size: 45px;
  letter-spacing: 4.57px;
  text-transform: uppercase;
  font-weight: normal;
}

.mobile-nav {
  display: none;
  top: -1000px;
}
.cross,
.burger {
  cursor: pointer;
}
.mobile-nav.is-open {
  font-family: "Helvetica Neue";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  position: fixed;
  background: white;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  padding-top: 12%;
  z-index: 1;
  transition-delay: 0.2s;
  transition-duration: 0.5s;
  align-self: center;
}

.mobile-link {
  margin-bottom: 115px;
  font-size: 30px;
  letter-spacing: 3.05px;
  transition-duration: 0.2s;
  color: black;
}
.mobile-link:hover {
  color: gray;
}

.mobile-nav .cross {
  position: absolute;
  right: 10%;
  top: 55px;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}

// @media (min-width: 1077px) {
//   .mobile-nav,
//   .mobile-nav.is-open {
//     display: none;
//love
//   }
// }
